import React from 'react';
import showcase_of_brainlog_app_mobile from '../../assets/images/screens/1 Main screen.png'
import googlePlayStore from '../../assets/images/google-play-store.png';
import './MainSection.css';
import { t } from '../../language/i18n';


function MainSection() {
  return (
    <section id="main"  className="main_placement">
      <div className="mainsection_container">
        <div className='app_showcase_placement'>
          <div className="app_showcase_container">
            <h1><span>{t("Simplify Your Life:")}</span><span>{t("Manage Information with Ease")}</span></h1>
            <div className="app_showcase_subcontainer">
              <p className="app_showcase_description">{t("Imagine a world where you never lose track of your notes and ideas. Make it a reality by starting your journey with BrainLog!")}</p>
              <div className="app_store_buttons_container">
                {/*https://play.google.com/apps/internaltest/4701093461764322843*/}
                <a href="https://play.google.com/store/apps/details?id=app.brainlog" target="_blank" rel="noreferrer">
                  <img src={googlePlayStore} alt={t("Get it on Google Play")}/>
                </a>{/* | 
                <a href="https://www.apple.com/app-store/">Download for iOS</a>*/}
                <p className="app_showcase_warning">{t("Take part in the beta test.")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='img_placement'>
          <h1><span>{t("Simplify Your Life:")}</span><span>{t("Manage Information with Ease")}</span></h1>
          <img src={showcase_of_brainlog_app_mobile} alt={t("App Screenshot")} />
        </div>
      </div>
    </section>
  );
}

export default MainSection;
