import React, { 
  //useEffect
 }  from 'react';
import { BrowserRouter as Router, Route, Routes,
   //useNavigate
   } from 'react-router-dom';
import Header from './components/Header';
//import HomeSection from './components/HomeSection';
//import Features from './components/Features';
//import HowItWorks from './components/HowItWorks';
//import AboutUs from './components/AboutUs';
//import Testimonials from './components/Testimonials';
//import Blog from './components/Blog';
//import Download from './components/Download';
import NotFoundPage from './components/NotFoundPage';
import PrivacyPolicy from './components/PrivacyPolicy';
//import TermsOfService from './components/TermsOfService';
import Footer from './components/Footer';

import ScrollToTop from './components/ScrollToTop';
//"homepage": "https://brainloger.github.io/brainlog",

import './App.css';
import HomePage from './components/home/HomePage';

function App() {



 return (
    <Router>
      <ScrollToTop />
      <div className="maindiv">
        <Header /> 
        <PagePlace/>
        <Footer />
          
      </div>
    </Router>
  );
}

function PagePlace() {

  return (
    <div className="pagePlace">
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        {/*<Route path="/terms_of_service" element={<TermsOfService />} />*/}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App; 

/*
npm start
debug: true, // Set to false in production
firebase logout
firebase login
npm run build
firebase hosting:channel:deploy CHANNEL_ID
firebase deploy --only hosting
*/
